.is-user-avatar {
  &.has-max-width {
    max-height: 7rem;
  }

  .user-avatar {
    height: 100%;
    max-height: 3rem;
    max-width: 3rem;
    border-radius: 100%;
  }
}

@media screen and (max-width: 768px) {
  div.preview {
    display: none;
  }
}
