/**  =====================
      Authentication css start
==========================  **/
@import "../../assets/scss/partials/variables";

body {
  background: $theme-light-color;
}

.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;

  a, p > a {
    color: $theme-heading-color;
    font-weight: 600;
  }
  p.help {
    &.is-danger {
      color: $danger-color;
    }
    &.is-success {
      color: $success-color;
    }
    width: 100%;
    margin-bottom: 0;
    margin-top: 0.25rem;
    height: 15px;
  }

  .btn-auth-gen {
    .btn-icon {
      width: 140px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 45px;

      small {
        font-size: 15px;
      }
    }
  }

  .input-group {
    background: transparent;
  }

  .card {
    margin-bottom: 0;
  }

  > div {
    z-index: 5;
  }

  .auth-content {
    position: relative;
    width: 390px;
    padding: 15px;
    z-index: 5;

    &.multyform,
    &.subscribe {
      width: 750px;
    }
  }

  .icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
  }

  .is-google {
    &:hover, &:not([href]):not([tabindex]) {
      color: #fff;
    }

    color: #fff;
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, .2);
  }

  .is-facebook {
    &:hover, &:not([href]):not([tabindex]) {
      color: #fff;
    }

    color: #fff;
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, .2);
  }

  @media only screen and (max-width: 768px) {
    max-width: 360px;

  }
  @media only screen and (max-width: 575px) {
    .card {
      .card-body {
        padding: 30px 15px;
      }
    }
  }

  .auth-icon {
    font-size: 30px;

    &:before {
      background: $theme-color;
      background-clip: inherit;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.offline {
    background-image: none;

    &:before {
      display: none;
    }
  }

  .auth-bg {
    .r {
      position: absolute;
      width: 300px;
      height: 300px;
      background-size: cover;
      background-image: url('../../assets/images/only-logo.png');
      background-repeat: no-repeat;
      background-position: center;

      &:first-child {
        top: -100px;
        right: -100px;
      }

      &:last-child {
        left: -100px;
        bottom: -100px;
        background-image: url('../../assets/images/only-logo-white.png');
      }

      &.s {
        width: 60px;
        height: 60px;

        &:nth-child(2) {
          top: 150px;
          right: -150px;
          background-image: url('../../assets/images/only-logo-white.png');
        }

        &:nth-child(3) {
          left: -150px;
          bottom: 150px;
        }
      }

      &:nth-child(odd) {
        animation: floating 7s infinite;
      }

      &:nth-child(even) {
        animation: floating 9s infinite;
      }
    }
  }
}

/* image varient start */
@keyframes floating {
  from {
    transform: rotate(0deg) translate(-10px) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}

/**====== Authentication css end ======**/
